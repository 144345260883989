import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import { DcListApi } from "@/api";
var columns = [{
  title: "类别编号",
  dataIndex: "id"
}, {
  title: "类别名称",
  dataIndex: "name"
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  data: function data() {
    return {
      title: "新增分类",
      createForm: this.$form.createForm(this, {
        name: "page_home"
      }),
      Visible: false,
      columns: columns,
      dataSource: [],
      rowInfo: {}
    };
  },
  watch: {
    rowInfo: {
      handler: function handler(nl, ol) {
        this.rowInfo = nl;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      DcListApi.getcategories().then(function (res) {
        _this.dataSource = res;
      });
    },
    handleAdd: function handleAdd(val) {
      var _this2 = this;
      if (val) {
        this.Visible = false;
      } else {
        this.createForm.validateFields(function (err, value) {
          // console.log(value, "value");

          if (!err) {
            var params = _objectSpread({}, value);
            // console.log(params);
            if (!_this2.rowInfo.id) {
              DcListApi.addcategories(params).then(function (res) {
                if (res) {
                  _this2.toast("新增成功", "success");
                  _this2.Visible = false;
                  _this2.getList();
                }
              });
            } else {
              params = _objectSpread(_objectSpread({}, params), {}, {
                categoryId: _this2.rowInfo.id
              });
              DcListApi.updcategories(params).then(function (res) {
                if (res) {
                  _this2.toast("修改成功", "success");
                  _this2.Visible = false;
                  _this2.getList();
                }
              });
            }
          } else {
            console.log(err);
            if (type == 3) {
              _this2.toast("请选择二级类目", "error");
            }
          }
        });
      }
    },
    showVisible: function showVisible(row) {
      var _this3 = this;
      console.log(row);
      if (row) {
        this.title = "修改分类";
        this.rowInfo = row;
        this.$nextTick(function () {
          _this3.createForm.setFieldsValue({
            name: row.name || ""
          });
        });
      } else {
        this.title = "新增分类";
        this.rowInfo = {};
      }
      this.Visible = true;
    },
    handleDelete: function handleDelete(id) {
      var _this4 = this;
      DcListApi.delcategories({
        id: id
      }).then(function (res) {
        if (res) {
          _this4.toast("删除成功", "success");
          _this4.getList();
        }
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};